// user profile
export const GET_USER_PROFILE = 'GET_USER_PROFILE';
export const USER_PROFILE_ERROR = 'USER_PROFILE_ERROR';

// Departments
export const GET_DEPARTMENTS = 'GET_DEPARTMENTS';
export const GETTING_DEPARTMENTS = 'GETTING_DEPARTMENTS';
export const CREATE_DEPARTMENT = 'CREATE_DEPARTMENT';
export const CREATING_DEPARTMENT = 'GETTING_DEPARTMENTS';
export const DEPARTMENTS_ERROR = 'DEPARTMENTS_ERROR';

// leave policies
export const UPDATING_LEAVE_POLICIES = 'UPDATING_LEAVE_POLICIES';
export const UPDATE_LEAVE_POLICIES = 'UPDATE_LEAVE_POLICIES';
export const LEAVE_POLICIES_ERROR = 'LEAVE_POLICIES_ERROR';

// leaves and leave applications
export const CREATING_LEAVE_APPLICATION = 'CREATING_LEAVE_APPLICATION';
export const CREATE_LEAVE_APPLICATION = 'CREATE_LEAVE_APPLICATION';
export const UPDATING_LEAVE_APPLICATION = 'UPDATING_LEAVE_APPLICATION';
export const UPDATE_LEAVE_APPLICATION = 'UPDATE_LEAVE_APPLICATION';
export const GETTING_LEAVE_APPLICATIONS = 'GETTING_LEAVE_APPLICATIONS';
export const GET_LEAVE_APPLICATIONS = 'GET_LEAVE_APPLICATIONS';
export const GETTING_LEAVES = 'GETTING_LEAVES';
export const GET_LEAVES = 'GET_LEAVES';
export const UPDATING_LEAVE = 'UPDATING_LEAVE';
export const UPDATE_LEAVE = 'UPDATE_LEAVE';
export const LEAVES_ERROR = 'LEAVES_ERROR'

// Documents
export const GET_DOCUMENTS = 'GET_DOCUMENTS';
export const GETTING_DOCUMENTS = 'GETTING_DEPARTMENTS';
export const CREATE_DOCUMENT = 'CREATE_DEPARTMENT';
export const UPDATING_DOCUMENT = 'UPDATING_DOCUMENTS';
export const UPDATE_DOCUMENT = 'UPDATE_DOCUMENT';
export const CREATING_DOCUMENT = 'GETTING_DEPARTMENTS';
export const DOCUMENTS_ERROR = 'DEPARTMENTS_ERROR';

// Groups
export const GET_GROUPS = 'GET_GROUPS';
export const GETTING_GROUPS = 'GETTING_GROUPS';
export const CREATE_GROUP = 'CREATE_GROUP';
export const CREATING_GROUP = 'CREATING_GROUP';
export const GROUPS_ERROR = 'GROUPS_ERROR';

// holidays
export const GET_HOLIDAYS = 'GET_HOLIDAYS';
export const GETTING_HOLIDAYS = 'GETTING_HOLIDAYS';
export const CREATE_HOLIDAY = 'CREATE_HOLIDAY';
export const CREATING_HOLIDAY = 'CREATING_HOLIDAY';
export const HOLIDAYS_ERROR = 'HOLIDAYS_ERROR';

// Shifts
export const GET_SHIFTS = 'GET_SHIFTS';
export const GETTING_SHIFTS = 'GETTING_SHIFTS';
export const GET_SHIFT_DETAILS = 'GET_SHIFT_DETAILS';
export const GETTING_SHIFT_DETAILS = 'GETTING_SHIFT_DETAILS';
export const CREATE_SHIFT = 'CREATE_SHIFT';
export const CREATING_SHIFT = 'CREATING_SHIFT';
export const UPDATE_SHIFT = 'UPDATE_SHIFT';
export const UPDATING_SHIFT = 'UPDATING_SHIFT';
export const SHIFTS_ERROR = 'SHIFTS_ERROR';

// Designations
export const GET_DESIGNATIONS = 'GET_DESIGNATIONS';
export const GETTING_DESIGNATIONS = 'GETTING_DESIGNATIONS';
export const CREATE_DESIGNATION = 'CREATE_DESIGNATION';
export const CREATING_DESIGNATION = 'CREATING_DESIGNATION';
export const DESIGNATIONS_ERROR = 'DESIGNATIONS_ERROR';

// Employees
export const GET_EMPLOYEES = 'GET_EMPLOYEES';
export const GETTING_EMPLOYEES = 'GETTING_EMPLOYEES';
export const INVITE_EMPLOYEE = 'INVITE_EMPLOYEE';
export const INVITING_EMPLOYEE = 'INVITING_EMPLOYEE';
export const SIGNUP_EMPLOYEE = 'SIGNUP_EMPLOYEE';
export const SIGNING_UP_EMPLOYEE = 'SIGNING_UP_EMPLOYEE';
export const EMPLOYEES_ERROR = 'EMPLOYEES_ERROR';

// News
export const GET_NEWS_ARTICLES = 'GET_NEWS_ARTICLES';
export const GETTING_NEWS_ARTICLES = 'GETTING_NEWS_ARTICLES';
export const CREATE_NEWS_ARTICLE = 'CREATE_NEWS_ARTICLES';
export const CREATING_NEWS_ARTICLE = 'CREATING_NEWS_ARTICLE';
export const NEWS_ERROR = 'NEWS_ERROR';

// Trainings
export const GET_TRAININGS = 'GET_TRAININGS';
export const GETTING_TRAININGS = 'GETTING_TRAININGS';
export const CREATE_TRAINING = 'CREATE_TRAINING';
export const CREATING_TRAINING = 'CREATING_TRAINING';
export const UPDATE_TRAINING = 'UPDATE_TRAINING';
export const UPDATING_TRAINING = 'UPDATING_TRAINING';
export const GET_TRAINING_MATERIALS = 'GET_TRAINING_MATERIALS';
export const GETTING_TRAINING_MATERIALS = 'GETTING_TRAINING_MATERIALS';
export const CREATE_TRAINING_MATERIAL = 'CREATE_TRAINING_MATERIAL';
export const CREATING_TRAINING_MATERIAL = 'CREATING_TRAINING_MATERIAL';
export const UPDATE_TRAINING_MATERIAL = 'UPDATE_TRAINING_MATERIAL';
export const UPDATING_TRAINING_MATERIAL = 'UPDATING_TRAINING_MATERIAL';
export const GET_TRAINING_MODULES = 'GET_TRAINING_MODULES';
export const GETTING_TRAINING_MODULES = 'GETTING_TRAINING_MODULES';
export const CREATE_TRAINING_MODULE = 'CREATE_TRAINING_MODULE';
export const CREATING_TRAINING_MODULE = 'CREATING_TRAINING_MODULE';
export const UPDATE_TRAINING_MODULE = 'UPDATE_TRAINING_MODULE';
export const UPDATING_TRAINING_MODULE = 'UPDATING_TRAINING_MODULE';
export const TRAININGS_ERROR = 'TRAININGS_ERROR';

// Instructors
export const GET_INSTRUCTORS = 'GET_INSTRUCTORS';
export const GETTING_INSTRUCTORS = 'GETTING_INSTRUCTORS';
export const CREATE_INSTRUCTOR = 'CREATE_INSTRUCTOR';
export const CREATING_INSTRUCTOR = 'CREATING_INSTRUCTOR';
export const UPDATE_INSTRUCTOR = 'UPDATE_INSTRUCTOR';
export const UPDATING_INSTRUCTOR = 'UPDATING_INSTRUCTOR';
export const INSTRUCTORS_ERROR = 'INSTRUCTORS_ERROR';

// Instructors
export const GET_ASSESSMENTS = 'GET_ASSESSMENTS';
export const GETTING_ASSESSMENTS = 'GETTING_ASSESSMENTS';
export const CREATE_ASSESSMENT = 'CREATE_ASSESSMENT';
export const CREATING_ASSESSMENT = 'CREATING_ASSESSMENT';
export const UPDATE_ASSESSMENT = 'UPDATE_ASSESSMENT';
export const UPDATING_ASSESSMENT = 'UPDATING_ASSESSMENT';
export const ASSESSMENTS_ERROR = 'ASSESSMENTS_ERROR';

// Errors
export const ERROR = 'ERROR'
export const CLEAR_ERROR = 'CLEAR_ERROR'

// Success
export const SET_SUCCESS_MESSAGE = 'SET_SUCCESS_MESSAGE'
export const CLEAR_SUCCESS_MESSAGE = 'CLEAR_SUCCESS_MESSAGE'