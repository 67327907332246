
import React, { useState, useEffect } from 'react';
import DotGridMintCream from '../../assets/img/dot_grid_mint_cream.svg'
// import Logo from '../../public/assets/img/logo-colored.svg'
// import GoogleLogo from '../../public/assets/img/Google__G__Logo.svg'
// import Link from 'next/link';
// import Head from 'next/head';
// import TextField from '../../../components/elements/form-elements/TextField';
// import Checkbox from '../../../components/elements/form-elements/Checkbox';
// import FormButton from '../../../components/elements/form-elements/FormButton';
import Notification from '../../components/elements/Notification';
import Preloader from '../../components/elements/Preloader';
import axios from 'axios'

import { Link, useNavigate, useParams } from 'react-router-dom';

const EmailConfirmation = () => {
    // const [ validationErrors, setValidationErrors ] = useState({})
    // const [ userDetails, setUserDetails ] = useState({})
    const [ notification, setNotification ] = useState(null)
    const [ processing, setProcessing ] = useState(true)
    const [ errorMessage, setErrorMessage ] = useState(null)

    // const [ agreeTerms, setAgreeTerms ] = useState(false)

    // const validateForm = () => {
    //     let errors = {}
    //     if(!userDetails.email || userDetails.email === '') {
    //         errors.email = true
    //     }

    //     if(!userDetails.name || userDetails.name === '') {
    //         errors.name = true
    //     }

    //     if(!userDetails.password || userDetails.password === '') {
    //         errors.password = true
    //     }

    //     if(!agreeTerms || agreeTerms === false) {
    //     errors.agreeTerms = true
    //     }

    //     setValidationErrors(errors)
    //     return errors
    // }
    const navigate = useNavigate()
    const params = useParams()
    const { token } = params

    useEffect(() => {
        const confirmEmail = async () => {
            try {
                // if (Object.values(validateForm()).includes(true)) {
                //     setNotification({
                //     show: true,
                //     success: false,
                //     message: 'Please fill in required field(s) highlighted'
                //     })
                //     return
                // }
    
                const headers = {
                    'Content-Type': 'application/json'
                }
    
                setProcessing(true)
                const requestPayload = {
                    token
                }
    
                await axios.post(`${process.env.NEXT_PUBLIC_BASE_URL}/auth/confirmation/email`, requestPayload, { headers }) 
                navigate('/login')
                
                setNotification({
                    show: true,
                    success: true,
                    message: 'Welcome! your account was successfully created. Please check your email to proceed'
                })
                setProcessing(false)
            } catch (error) {
                setErrorMessage(error.response.data.message)
                setNotification({
                    show: true,
                    success: false,
                    message: error.response.data.message
                })
                setProcessing(false)
            }
        }

        confirmEmail()
      
    
    }, [])
    



    return (
        <div className='w-full block lg:flex flex-row min-h-screen bg-secondary'>
            {notification && <Notification type={`${notification.success === true ? 'success' : 'error'}`} isShowing={notification.show} message={notification.message} dismissHandler={()=>{setNotification(null)}} />}

            {/* <Head>
                <title>Sign up | Tribez Marketplace</title>
                <meta name="description" content="" />
            </Head> */}
            <div className='hidden lg:block w-full bg-primary bg-opacity-10 h-screen'>
                <div className='absolute left-0 bottom-0 w-3/12 z-20'>
                    <img src={DotGridMintCream} alt='' />
                </div>
            </div>
            
            <div className='w-full h-screen'>
                <div className='w-10/12 lg:w-3/6 ml-auto mr-auto'>
                    <div className='w-36 pt-24'>
                        {/* <Image src={Logo} alt='' /> */}
                        <h1>SocialSeed</h1>
                    </div>

                    <div className='w-full mt-10 z-20'>

                        <div className='relative flex items-center'>
                                <p className='w-full px-3 my-10 text-gray-400 text-md z-20'><span className='font-medium'>Please wait...</span> your email address is being confirmed</p>
                        </div>

                        {processing ? 
                            <div className='w-full text-center'>
                                <Preloader /> 
                            </div>
                            :
                            // <FormButton buttonLabel="Create your account" buttonAction={()=>{signUp()}} />
                            <div className='px-6 py-6 rounded-lg bg-red-600 bg-opacity-10'>
                                <p className='text-red-500 text-lg'>Sorry an error occured</p>
                                <p className='text-md text-red-300 font-medium mt-2'>{errorMessage}</p>
                            </div>
                        }

                        <p className='my-5 text-primary'><span className='text-gray-400'>Already confirmed your email? </span>
                        <Link  to="/login" className="font-medium text-primary">Sign in here</Link>
                        </p>

                    </div>
                </div>

            </div>

        </div>
    )
}

export default EmailConfirmation