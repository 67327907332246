import './App.css';
import ErrorNotifier from './components/elements/ErrorNotifier';
import SuccessNotifier from './components/elements/SuccessNotifier';
import ScrollToTop from './components/layout/ScrollToTop';
import { Provider } from 'react-redux';
import store from './store/store';
import { Route, Routes } from 'react-router-dom';
import Login from './pages/auth/Login';
import PasswordReset from './pages/auth/PasswordReset';
import Signup from './pages/onboarding/Signup';
import EmailConfirmation from './pages/onboarding/EmailConfirmation';
import User from './pages/User';
import ErrorPage from './pages/ErrorPage';

function App() {
  return (
    <Provider store={store}>
      <ErrorNotifier />
      <SuccessNotifier />
      <ScrollToTop>
        <Routes>
          <Route path="/" exact element={<Login />} />
          <Route path="/password-reset" exact element={<PasswordReset />} />

          <Route path="/signup" exact element={<Signup />} />
          <Route path="/confirm-email/:confirmationCode" exact element={<EmailConfirmation />} />
          
          <Route path="/user" element={<User />}>

          </Route>
          <Route path="*" element={<ErrorPage />} />
        </Routes>
      
      </ScrollToTop>
    </Provider>
  );
}

export default App;
