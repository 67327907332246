import React, { useState } from 'react'
import OTPInput from '../elements/form/OtpInput'
import FormButton from '../elements/form/FormButton'
import { useDispatch } from 'react-redux'
import axios from 'axios'
import { ERROR, SET_SUCCESS_MESSAGE } from '../../store/types'
import ChevronIcon from '../elements/icons/ChevronIcon'
import Countdown from '../elements/Countdown'

const EmailConfirmation = ({email, onSuccess}) => {
    const dispatch = useDispatch()
    const [counted, setCounted] = useState(false);
    const [otpSent, setOtpSent] = useState(false);
    const [otp, setOtp] = useState();
    const [processing, setProcessing] = useState(false);
    const [validationErrors, setValidationErrors] = useState({});

    const validateEmailConfirmationForm = () => {
        let errors = {}
        if(!otp || otp === '') {
            errors.otp = true
        }

        setValidationErrors(errors)
        return errors
    }


    const resendOtp = async () => {
        try {
            const headers = {
                'Content-Type': 'application/json'
            }
            const requestPayload = { 
                email: email,
            }
            
            await axios.post(`${process.env.REACT_APP_BASE_URL}/onboarding/email-confirmation/resend`, requestPayload, { headers }) 
            // setProcessing(false)
            setOtpSent(true)
            setCounted(false)
            dispatch({
                type: SET_SUCCESS_MESSAGE,
                payload: 'Confirmation code has been resent to your email. Please check'
            })
        } catch (error) {
            console.log(error)
            dispatch({
                type: ERROR,
                error
            })
            // setProcessing(false)
        }
    }

    const confirmEmail = async () => {
        try {
            if (Object.values(validateEmailConfirmationForm()).includes(true)) {
                dispatch({
                    type: ERROR,
                    error: {response: {data: {
                        message: 'Please check the highlighted fields'
                    }}}
                })
                return
            }

            const headers = {
                'Content-Type': 'application/json'
            }

            setProcessing(true)

            const requestPayload = {
                confirmationCode: otp
            }

            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/onboarding/confirm-email`, requestPayload, { headers }) 
            localStorage.setItem("authToken", JSON.stringify(response.data.data.data.accessToken));

            setProcessing(false)
            onSuccess()

            dispatch({
                type: SET_SUCCESS_MESSAGE,
                payload: 'Email address confirmed successfully'
            })
        } catch (error) {
            dispatch({
                type: ERROR,
                error
            })
            setProcessing(false)
        }
    }
  return (
    <div className='w-full'>
        <div className='w-full my-[20px]'>
            <OTPInput length={6} onChange={(value)=>{setOtp(value)}} hasError={validationErrors && validationErrors.otp} />
        </div>

        <p className='text-gray-600 text-sm'>Please provide the OTP sent to your email address ({email}) to confirm your email.</p>

        <div className='w-full mt-3'>
            {counted && !otpSent
                ? 
                    <div className='py-2'>
                        <button className="text-asparagus text-sm font-[500]" onClick={()=>{resendOtp()}}>Resend Confirmation Email</button>
                    </div>
                :
                <>
                    <p className='py-2 text-gray-500 text-sm mt-2'>Didn't get the email? You can retry and/or correct your email address in <Countdown seconds={60} className='inline text-green-500 font-medium' countdownComplete={()=>{
                        setCounted(true)
                        setOtpSent(false)
                    }} /> seconds
                    </p>
                </>
            }
        </div>

        <div className='mt-3'>
            <FormButton buttonLabel={<p className='flex items-center gap-x-[8px] font-space-grotesk'>Confirm Email <ChevronIcon className={`w-4 h-4 `} /> </p>} buttonAction={()=>{confirmEmail()}} processing={processing} />
        </div>
    </div>
  )
}

export default EmailConfirmation