
import React, { useState, useEffect } from 'react';
// import { useRouter } from 'next/router'
import axios from 'axios'
import { Link, useNavigate } from 'react-router-dom';
import TextField from '../../components/elements/form/TextField';
import Checkbox from '../../components/elements/form/Checkbox';
import FormButton from '../../components/elements/form/FormButton';
import Logo from '../../components/elements/Logo';
import { ERROR } from '../../store/types';
import { useDispatch } from 'react-redux';
import EmailConfirmation from '../../components/partials/EmailConfirmation';
import PasswordField from '../../components/elements/form/PasswordField';
import CloseIcon from '../../components/elements/icons/CloseIcon';

const Login = () => {
    const [ rememberMe, setRememberMe ] = useState(false)
    const toggleRememberMe = () => {
        setRememberMe(!rememberMe)
    }

    const navigate = useNavigate()
    const [ authDetails, setAuthDetails ] = useState({})
    const [ validationErrors, setValidationErrors ] = useState({})
    const [ processing, setProcessing ] = useState(false)
    const dispatch = useDispatch()

    useEffect(() => {

      }, []);

    const validateForm = () => {
        let errors = {}
        if(!authDetails.email || authDetails.email === '') {
            errors.email = true
        }

        if(!authDetails.password || authDetails.password === '') {
            errors.password = true
        }
    
        setValidationErrors(errors)
        return errors
      }
    
      const logIn = async  () => {
        const headers = {
            'Content-Type': 'application/json'
        }
        
        if (Object.values(validateForm()).includes(true)) {

            dispatch({
                type: ERROR,
                error: {response: {data: {
                    message: 'Please check the highlighted fields'
                }}}
            })
            return
        }

        setProcessing(true)

        const requestPayload = authDetails
        try {
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/auth/sessions`, requestPayload, { headers })            

            localStorage.setItem("authToken", JSON.stringify(response.data.data.accessToken));
            getUserProfile(response.data.data.accessToken)
        } catch (error) {
            console.log(error)
            if(error.response.data.errorCode === 'email-confirmation-pending'){
                setPendingEmailConfirmation(true)
            } else {
                dispatch({
                    type: ERROR,
                    error
                })
            }
            setProcessing(false)
        }
    }

    const [displayPendingCharities, setDisplayPendingCharities] = useState(false);
    const [pendingEmailConfirmation, setPendingEmailConfirmation] = useState(false);

    const getUserProfile = async  (token) => {
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
        
        try {
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/user/profile?expand=charities.charity,charities.role`, { headers })     
            console.log('---> ', response)       

            if(!response.data.data.charities || response.data.data.charities.length === 0){
                dispatch({
                    type: ERROR,
                    error: {response: {data: {
                        message: 'Sorry, you cannot log in to this platform, you have no charities registered.'
                    }}}
                })
                // localStorage.removeItem("authToken")
                navigate('signup?continueFrom=2')
                setProcessing(false)
                return
            }

            if(response.data.data.charities && response.data.data.charities.length > 0){
                const indexOfFirstApprovedCareHome = response.data.data.charities.findIndex(home => {return home.charity.review.status === 'approved' &&  home.careHome.status === 'active'})
                if(indexOfFirstApprovedCareHome > - 1) {
                    localStorage.setItem("userDetails", JSON.stringify(response.data.data));
                    localStorage.setItem("activeCharity", JSON.stringify(response.data.data.charities[indexOfFirstApprovedCareHome]));
                    navigate("/user")
                } else {
                    setDisplayPendingCharities(true)
                    setProcessing(false)
                    return
                }
                
            } 
            // else {
            //     dispatch({
            //         type: ERROR,
            //         error: {response: {data: {
            //             message: 'Sorry, you cannot log in to this platform, you have no approved.'
            //         }}}
            //     })
            //     localStorage.removeItem("authToken")
            //     setDisplayPendingCharities(true)

            //     // navigate('signup?continueFrom=2')
            //     setProcessing(false)
            //     return
            // }

        } catch (error) {
            console.error(error)
            dispatch({
                type: ERROR,
                error
            })
            setProcessing(false)
        }
    }

    return (
        <div className='w-full flex items-start justify-center min-h-screen bg-lavender-gray pt-[50px]'>
            {/* <Preloader /> */}
            {/* <div className='w-full bg-secondary  lg:pb-0'> */}
                <div className='bg-white w-10/12 lg:w-4/12 p-[40px] mx-auto rounded-[12px] shadow-xl shadow-asparagus/10'>
                    <div className='w-36'>
                        <Logo />
                    </div>

                    <div className='w-full mt-10 z-20'>
                        <div className='w-full'>
                            <TextField
                                inputLabel="Email address"
                                inputPlaceholder="someone@somewhere.com"
                                inputType="email" 
                                preloadValue={''}
                                hasError={validationErrors.email} 
                                returnFieldValue={(fieldValue)=>{setAuthDetails({...authDetails, ...{email: fieldValue}})}}
                            />
                        </div>

                        <div className='w-full mt-[10px] relative'>
                            <PasswordField
                                inputLabel="Your password"
                                inputPlaceholder="Somth!ngSecur3"
                                preloadValue={''}
                                inputType="password" 
                                hasError={validationErrors.password} 
                                returnFieldValue={(fieldValue)=>{setAuthDetails({...authDetails, ...{password: fieldValue}})}}
                            />
                        </div>

                        {!displayPendingCharities && !pendingEmailConfirmation && <>
                            <div className='my-5 w-max'>
                                <Checkbox CheckboxLabel="Remember me" isChecked={rememberMe} checkboxToggleFunction={toggleRememberMe} />
                            </div>

                            <p className='my-5 text-primary'><span className='text-gray-400'>Forgot password? </span><Link to="/password-reset" className="font-medium text-primary">Click here to reset it</Link></p>
                            
                            <div className='w-full my-4'> 
                                <FormButton buttonLabel="Log in" buttonAction={()=>{logIn()}} processing={processing} />
                            </div>
                        </>}


                        {!processing && !displayPendingCharities &&  pendingEmailConfirmation && <div className='p-5'>
                            <p className='my-5 text-primary'>Your email address is still pending confirmation.</p>
                            
                            <EmailConfirmation
                                email={authDetails.email}
                                onSuccess={()=>{
                                    navigate('signup?continueFrom=2')
                                }}
                            />
                        </div>}

                        {displayPendingCharities && <div className='mt-5 w-full mx-auto text-center p-[20px] rounded-[10px] bg-gray-100 border-2 border-asparagus/10 relative'>
                            <button className='absolute right-[10px] top-[10px]' onClick={()=>{setDisplayPendingCharities(false)}}><CloseIcon className={'w-4 h-4'} /></button>
                            <p className='font-space-grotesk text-xl text-asparagus'>Your charity is in review</p>
                            <p className='mt-[5px] text-sm font-[500] text-gray-600'>Sorry, we are still reviewing your charity. You will receive an email at your registered email address once it is approved.</p>
                        </div>}


                        {/* <button className={`${PrimaryButton}`}>
                            <p className='font-medium'>
                                <Link href="onboarding">Log in to your account</Link></p>
                        </button> */}

                        <p className='my-5 text-primary'><span className='text-gray-400'>Don&apos;t have an account? </span><Link to="/signup" className="font-medium text-primary">Create an account here</Link></p>
                    </div>
                </div>
            {/* </div> */}
        </div>
    )
}

export default Login