import React from 'react'
// import styles from "../../styles/Preloader.module.css";
// import 'ldrs/ring2'

const Preloader = ({preloadingText}) => {
  return (
    // Default values shown  
    // <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
    <div className='w-full h-[300px] flex items-center justify-center text-center'>
      <div className='text-center flex flex-col items-center'>
        <div className='loader' />
        {preloadingText && <p className='text-sm text-gray-400 mt-[10px] font-[550]'>{preloadingText}</p>}
      </div>
    </div>

  )
}

export default Preloader


