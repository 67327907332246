import React, { useRef } from 'react';
import confetti from 'canvas-confetti';

const Confetti = ({ trigger }) => {
  const canvasRef = useRef(null);

  const launchConfetti = () => {
    const myConfetti = confetti.create(canvasRef.current, {
      resize: true,
      useWorker: true,
    });

    myConfetti({
      particleCount: 150,
      spread: 70,
      origin: { y: 0.6 },
    });
  };

  React.useEffect(() => {
    if (trigger) {
      launchConfetti();
    }
  }, [trigger]);

  return <canvas ref={canvasRef} className="fixed top-0 left-0 w-full h-full pointer-events-none" />;
};

export default Confetti;
