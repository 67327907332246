import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { CLEAR_ERROR } from '../../store/types';
import ErrorMessage from './ErrorMessage';
// import LoginModal from './LoginModal';

const ErrorNotifier = () => {
    const dispatch = useDispatch();
    const error = useSelector(state => state?.errors?.error);
    const dismissHandler = () => {
        dispatch( {
            type: CLEAR_ERROR
        })
    }

    if (!error) return null;

    if(error && error.statusCode !== 401) {
        console.log('error notifier: ', error)
        setTimeout(() => {
            dismissHandler()
        }, 5000);
        
        return (
            <ErrorMessage message={error.message} dismissHandler={()=>{dismissHandler()}} />
        )
    }

}

export default ErrorNotifier
