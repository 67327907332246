import React, { useEffect, useState } from 'react';
import TextField from '../../components/elements/form/TextField';
import Checkbox from '../../components/elements/form/Checkbox';
import FormButton from '../../components/elements/form/FormButton';
import Preloader from '../../components/elements/Preloader';
// import axios from 'axios'
import { Link, useSearchParams } from 'react-router-dom';
import Logo from '../../components/elements/Logo';
import PasswordField from '../../components/elements/form/PasswordField';
import UserIcon from '../../components/elements/icons/UserIcon';
import EnvelopeOpenIcon from '../../components/elements/icons/EnvelopeOpenIcon';
import HandHeartIcon from '../../components/elements/icons/HandHeartIcon';
// import RocketIcon from '../../components/elements/icons/RocketIcon';
import CheckBadgeIcon from '../../components/elements/icons/CheckBadgeIcon';
import { useDispatch } from 'react-redux';
import { ERROR, SET_SUCCESS_MESSAGE } from '../../store/types';

import ChevronIcon from '../../components/elements/icons/ChevronIcon';
import TextareaField from '../../components/elements/form/TextareaField';
import SelectField from '../../components/elements/form/SelectField';
import axios from 'axios';
import AutocompleteSelect from '../../components/elements/form/AutocompleteSelect';
import { Nationalities } from '../../assets/static/nationalities';
import { authHeader, parseNigerianStates, slugify } from '../../utils/utils';
import { StatesLgas } from '../../assets/static/stateslgas';
import TrashIcon from '../../components/elements/icons/TrashIcon';
import Confetti from '../../components/elements/Confetti';
import ArrowIcon from '../../components/elements/icons/ArrowIcon';
import EmailConfirmation from '../../components/partials/EmailConfirmation';

const Signup = () => {
    const dispatch = useDispatch()
    const [ loading, setLoading] = useState(true);
    const [ categories, setCategories ] = useState([]);
    const [ fundingSources, setFundingSources ] = useState([]);
    const [ validationErrors, setValidationErrors ] = useState({})
    const [ userDetails, setUserDetails ] = useState({})
    const [ processing, setProcessing ] = useState(false)
    const [ agreeTerms, setAgreeTerms ] = useState(false)
    const [ charityPayload, setCharityPayload ] = useState({fundingSources: []});
    const [ subCategoriesActive, setSubCategoriesActive ] = useState(false);
    const [ activeSubCategories, setActiveSubCategories ] = useState(null);

    const [searchParams] = useSearchParams();

    useEffect(() => {
        if(searchParams.get("continueFrom")){
            setActiveStep(parseInt(searchParams.get("continueFrom")))
        }

        const fetchCategories = async () => {
            try {
                const headers = {
                    'Content-Type': 'application/json'
                }
    
                setLoading(true)
                const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/charity-categories`, { headers }) 
                setCategories(response.data.data)
                fetchFundingSources()
            } catch (error) {
                console.log(error)
                dispatch({
                    type: ERROR,
                    error
                })
                setLoading(false)
            }
        }

        const fetchFundingSources = async () => {
            try { 
                const headers = {
                    'Content-Type': 'application/json'
                }
    
                setLoading(true)
                const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/charity-funding-sources`, { headers }) 
                setFundingSources(response.data.data)
                setLoading(false)
            } catch (error) {
                dispatch({
                    type: ERROR,
                    error
                })
                setLoading(false)
            }
        }

        fetchCategories()
        return () => {
            
        };
    }, [dispatch, searchParams]);

    const selectCategory = (categoryObject) => {
        console.log('-> ', categoryObject)
        setCharityPayload({...charityPayload, ...{category: categoryObject.slug}})
        setActiveSubCategories(categoryObject.subCategories)
        setTimeout(() => {
            setSubCategoriesActive(true)
        }, 100);
    }

    const [activeLgas, setActiveLgas] = useState([])
    const [lgasEnabled, setLgasEnabled] = useState(false)

    const selectState = (selectedState) => {
        setLgasEnabled(false)
        const selectedStateLgas = StatesLgas.find((state)=>{
            return state.stateSlug === slugify(selectedState.label)
        }).lgas

        console.log(selectedStateLgas)
        setActiveLgas(selectedStateLgas)

        setTimeout(() => {
            setLgasEnabled(true)
        }, 50);

        setCharityPayload({...charityPayload, ...{state: selectedState.label}})
    }


    const validateUserDetailsForm = () => {
        let errors = {}
        if(!userDetails.email || userDetails.email === '') {
            errors.email = true
        }

        if(!userDetails.name || userDetails.name === '') {
            errors.name = true
        }

        if(!userDetails.phone || userDetails.phone === '') {
            errors.phone = true
        }

        if(!userDetails.password || userDetails.password === '') {
            errors.password = true
        }

        if(!agreeTerms || agreeTerms === false) {
        errors.agreeTerms = true
        }

        setValidationErrors(errors)
        return errors
    }


    const [activeStep, setActiveStep] = useState(0);
    
    // const [otp, setOtp] = useState(null);

    const signUp = async () => {
        try {
            if (Object.values(validateUserDetailsForm()).includes(true)) {
                dispatch({
                    type: ERROR,
                    error: {response: {data: {
                        message: 'Please check the highlighted fields'
                    }}}
                })
                return
            }

            const headers = {
                'Content-Type': 'application/json'
            }

            setProcessing(true)

            const requestPayload = userDetails

            await axios.post(`${process.env.REACT_APP_BASE_URL}/onboarding/user/signup`, requestPayload, { headers }) 
            setProcessing(false)
            setActiveStep((activeStep) => activeStep + 1)
            dispatch({
                type: SET_SUCCESS_MESSAGE,
                payload: 'Please check your email to confirm.'
            })
        } catch (error) {
            dispatch({
                type: ERROR,
                error
            })
            setProcessing(false)
        }
    }


    const validateCharityForm = () => {
        let errors = {}
        if(!charityPayload.cacRegNumber || charityPayload.cacRegNumber === '') {
            errors.cacRegNumber = true
        }

        if(!charityPayload.registrationYear || charityPayload.registrationYear === '') {
            errors.registrationYear = true
        }

        if(!charityPayload.startOfOperationsYear || charityPayload.startOfOperationsYear === '') {
            errors.startOfOperationsYear = true
        }

        if(!charityPayload.name || charityPayload.name === '') {
            errors.charityName = true
        }

        if(!charityPayload.about || charityPayload.about === '') {
            errors.about = true
        }

        if(!charityPayload.category || charityPayload.category === '') {
            errors.category = true
        }

        if(!charityPayload.subCategory || charityPayload.subCategory === '') {
            errors.subCategory = true
        }

        if(!charityPayload.fundingSources || charityPayload.fundingSources.length === 0) {
            errors.subCategory = true
        }

        keyPersonnel.forEach((person, personIndex) => {
            if(!person.name || person.name === '') {
                errors[`person-${personIndex}-name`] = true
            }

            if(!person.position || person.position === '') {
                errors[`person-${personIndex}-position`] = true
            }
        });


        if(!termsAccepted || termsAccepted === false) {
            errors.termsAccepted = true
        }

        if(!acknowledged || acknowledged === false) {
            errors.acknowledged = true
        }

        console.log(errors)

        setValidationErrors(errors)
        return errors
    }

    const createCharity = async () => {
        try {
            if (Object.values(validateCharityForm()).includes(true)) {
                dispatch({
                    type: ERROR,
                    error: {response: {data: {
                        message: 'Please check the highlighted fields'
                    }}}
                })
                return
            }

            const headers = authHeader()

            setProcessing(true)

            const requestPayload = {
                name: charityPayload.name,
                about: charityPayload.about,
                contact: {
                    address: {
                        address: charityPayload.address,
                        city: charityPayload.lga,
                        state: charityPayload.state,
                        countryCode: charityPayload.countryCode,
                        country: charityPayload.country,
                    },
                    email: charityPayload.contactEmail,
                    phone: charityPayload.contactPhone
                    // alternatePhone: string
                },
                website: charityPayload.website,
                fundingSources: charityPayload.fundingSources,
                category: {
                    category: charityPayload.category,
                    subCategory: charityPayload.subCategory
                },
                keyPersonnel: keyPersonnel,
                cacRegNumber: charityPayload.cacRegNumber,
                registrationYear: charityPayload.registrationYear,
                startOfOperationsYear: charityPayload.startOfOperationsYear,
                invalidateSession: true
            }

            await axios.post(`${process.env.REACT_APP_BASE_URL}/onboarding/charities`, requestPayload, { headers }) 
            
            dispatch({
                type: SET_SUCCESS_MESSAGE,
                payload: 'Welcome aboard'
            })
            setProcessing(false)
            setSignupComplete(true)
            handleCelebrate()

        } catch (error) {
            console.log(error)
            dispatch({
                type: ERROR,
                error
            })
            setProcessing(false)
        }
    }

    const [cacSaved, setCacSaved] = useState(false);
    const receiveCACNumber = () => {
        // setActiveSubStep(1)
        setCacSaved(true)
    }
    
    const saveCharityDetails = () => {
        setActiveSubStep(1)
    }
    
    const saveContactDetails = () => {
        setActiveSubStep(2)
    }
    
    const saveKeyPersonnel = () => {
        // setActiveSubStep(4)
        setActiveStep(activeStep+1)
    }

    // const [counted, setCounted] = useState(false);
    // const [otpSent, setOtpSent] = useState(false);

    const signupSteps = [
        {
            label: "Your user details",
            icon: UserIcon,
            description: 'Provide details for your user account.'
        },
        {
            label: "Confirm your email address",
            icon: EnvelopeOpenIcon,
            description: 'We need to make sure you have provided an active email address'
        },
        {
            label: "Details of your charity",
            icon: HandHeartIcon,
            description: 'Tell us about your charitable organization',
            subSteps: [
                {
                    label: 'About the Charity',
                    description: 'Details of your charity and registration with CAC'
                },
                {
                    label: 'Contact Details',
                    description: 'How can we reach your charity if we need to.'
                },
                {
                    label:"Key Personnel",
                    description: "Some key personnel in your Charity"
                }
            ]
        },
        {
            label: "Acknowledgements",
            icon: CheckBadgeIcon,
            description: 'Acknowledge our terms and your authority'
        },
        // {
        //     label: "Welcome!",
        //     icon: RocketIcon,
        //     description: `You're good to go... Welcome aboard! let's make an impact together.`
        // },
    ]

    const [activeSubStep, setActiveSubStep] = useState(0);

    const keyPerson = {
        name: '',
        position: ''
    }
    const [keyPersonnel, setKeyPersonnel] = useState([keyPerson]);

    const personnelPositions = [
        { 
            label: 'Co-Founder', 
            value: 'co-founder' 
        },
        { 
            label: 'Chairperson', 
            value: 'chairperson' 
        },
        { 
            label: 'Director', 
            value: 'director' 
        },
        { 
            label: 'Board member', 
            value: 'board-member' 
        },
        { 
            label: 'Country manager', 
            value: 'country-manager' 
        },
        { 
            label: 'Treasurer', 
            value: 'treasurer' 
        },
        { 
            label: 'Other', 
            value: 'other' 
        },
    ]

    const addPerson = () => {
        setKeyPersonnel([...keyPersonnel, keyPerson]);
    }

    const removePerson = (index) => {
        setKeyPersonnel(keyPersonnel.filter((person, i) => i !== index));
    }

    const updatePerson = (index, field, value) => {
        let tempKeyPersonnel = [...keyPersonnel]
        tempKeyPersonnel[index][field] = value
        setKeyPersonnel(tempKeyPersonnel)
    }

    const toggleFundingSources = (sourceIndex) => {
        let tempPayload = {...charityPayload}
        if(tempPayload?.fundingSources?.includes(fundingSources[sourceIndex].slug)){
            tempPayload.fundingSources.splice(tempPayload.fundingSources.indexOf(fundingSources[sourceIndex].slug), 1)
        } else {
            tempPayload.fundingSources.push(fundingSources[sourceIndex].slug)
        }

        setCharityPayload(tempPayload)
    }
    const [termsAccepted, setTermsAccepted] = useState(false);
    const [acknowledged, setAcknowledged] = useState(false);
    const [signupComplete, setSignupComplete] = useState(false);
    const [showConfetti, setShowConfetti] = useState(false);

    const handleCelebrate = () => {
        setShowConfetti(true);
        setTimeout(() => setShowConfetti(false), 2000); // Hide confetti after 2 seconds
    };

    return (
        <>
            {loading 
                ? 
                <Preloader preloadingText={`loading resources`} /> 
                :
                <div className='w-full block lg:flex flex-row min-h-screen bg-secondary'>
                    
                    <div className='w-full h-screen p-[20px]'>
                        {!signupComplete && <div className='flex justify-between gap-x-[20px]'>
                            <div className='w-3/12 rounded-[16px] bg-lavender-gray bg-opacity-30 p-[50px] flex-1'>
                                {signupSteps.map((step,stepIndex)=>(<div key={stepIndex} className='flex items-start gap-x-[10px]'>
                                    <div className='w-[50px] flex items-center justify-center z-40'>
                                        <div className={`rounded-[8px] w-[40px] h-[40px] bg-white border-[2px] flex items-center justify-center ${activeStep >= stepIndex ? 'border-asparagus' : 'border-gray-200'}`}>
                                            {<step.icon className={`w-5 h-5 ${activeStep >= stepIndex ? 'text-asparagus' : 'text-gray-300'}`} />}
                                        </div>
                                    </div>
                                    <div className={`z-10 w-[80%] min-h-[100px] h-inherit pb-[20px] pl-[30px] border-l-2 -ml-[35px] ${stepIndex < signupSteps.length - 1 ? 'border-gray-300' : 'border-transparent'}`}>
                                        <h6 className='text-[14px] text-gunmetal-black font-[400] font-space-grotesk'>{step.label}</h6>
                                        <p className='text-[13px] text-gray-500 font-[400]'>{step.description}</p>

                                        {step.subSteps && <div className='mt-[10px]'>
                                            {step.subSteps.map((subStep, subStepIndex)=>(<div key={subStepIndex} className='flex items-start gap-x-[10px] my-[10px]'>
                                                <div className='w-[10px]'>
                                                    <div className={`w-[7px] h-[7px] mt-[10px] rounded-full ${activeStep === 2 && activeSubStep >= subStepIndex ? 'bg-asparagus' : 'bg-gray-300'}`} />
                                                </div>
                                                <div className='w-full'>
                                                    <h6 className='text-[14px] text-gray-700 font-[500]'>{subStep.label}</h6>
                                                    <p className='text-[12px] text-gray-500 font-[400]'>{subStep.description}</p>
                                                </div>
                                            </div>))}
                                        </div>}
                                    </div>
                                </div>))}

                                <p className='mt-5 text-primary'><span className='text-gray-400 text-sm'>Already have an account? <br /> </span>
                                    <Link to="/" className="font-medium text-primary">Sign in here</Link>
                                    </p>
                            </div>
                            <div className='w-9/12 ml-auto mr-auto h-[96vh] mb-[100px]'>
                                <div className='w-5/12 mx-auto mt-10 z-20'>
                                    <div className='w-36'>
                                        <Logo />
                                    </div>
                                    <div className='mb-[32px]'>
                                        <p className='text-gray-500 text-[15px] font-[500] font-space-grotesk'>Create an account for free</p>
                                        <p className='text-gray-500 text-[14px] font-[400] mt-[5px]'>{signupSteps[activeStep].description}</p>
                                    </div>
                                </div>
                                {activeStep === 0 && <div className='w-5/12 mx-auto mt-10 z-20'>
                                    <div className='w-full my-[20px]'>
                                        <TextField
                                            inputLabel="Full Name"
                                            inputPlaceholder="Eg: John Jackson Wick"
                                            inputType="text" 
                                            requiredField={true}
                                            preloadValue={userDetails.name || ''}
                                            hasError={validationErrors.name} 
                                            returnFieldValue={(fieldValue)=>{setUserDetails({...userDetails, ...{name: fieldValue}})}}
                                        />
                                    </div>

                                    <div className='w-full my-[20px]'>
                                        <TextField
                                            inputLabel="Phone number"
                                            inputPlaceholder="08012345678" 
                                            preloadValue={userDetails.phone || ''}
                                            requiredField={true}
                                            hasError={validationErrors.phone} 
                                            returnFieldValue={(fieldValue)=>{setUserDetails({...userDetails, ...{phone: fieldValue}})}}
                                        />
                                    </div>

                                    <div className='w-full my-[20px]'>
                                        <TextField
                                            inputLabel="Email address"
                                            inputPlaceholder="someone@somewhere.com"
                                            inputType="email" 
                                            requiredField={true}
                                            preloadValue={userDetails.email || ''}
                                            hasError={validationErrors.email} 
                                            returnFieldValue={(fieldValue)=>{setUserDetails({...userDetails, ...{email: fieldValue}})}}
                                        />
                                    </div>

                                    <div className='w-full relative my-[20px]'>
                                        <PasswordField
                                            inputLabel="Choose a password"
                                            inputPlaceholder="Somth!ngSecur3"
                                            requiredField={true}
                                            inputType="password" 
                                            preloadValue={userDetails.password || ''}
                                            hasError={validationErrors.password} 
                                            returnFieldValue={(fieldValue)=>{setUserDetails({...userDetails, ...{password: fieldValue}})}}
                                        />
                                        
                                    </div>

                                    <div className='w-full mt-6 mb-6 ml-auto mr-auto'>
                                        <Checkbox 
                                            CheckboxLabel={<p className='text-gray-400'>I agree to socialseed <a href='https://www.google.com' target="_blank" className='text-primary font-medium' rel="noreferrer">terms of service</a> and <a href='https://www.google.com' target="_blank" className='text-primary font-medium' rel="noreferrer">privacy policy</a></p>} 
                                            isChecked={agreeTerms} 
                                            checkboxToggleFunction={()=>{setAgreeTerms(!agreeTerms)}} 
                                            hasError={validationErrors && validationErrors.agreeTerms}
                                        />
                                    </div>

                                    <FormButton buttonLabel="Create your account" buttonAction={()=>{signUp()}} processing={processing}  />

                                </div>}

                                {activeStep === 1 && <div className='w-5/12 mx-auto mt-10 z-20'>
                                    <EmailConfirmation 
                                        email={userDetails.email}
                                        onSuccess={()=>{
                                            setActiveStep(2)
                                        }}
                                    />

                                </div>}

                                {activeStep === 2 && <div className='w-5/12 mx-auto mt-10 z-20'>
                                
                                    <div className='w-full my-[20px]'>
                                        <TextField
                                            inputLabel="CAC Registration Number"
                                            inputPlaceholder="RC785412369"
                                            inputType="text" 
                                            hasError={validationErrors.cacNumber} 
                                            requiredField={true}
                                            preloadValue={charityPayload.cacRegNumber || ''}
                                            returnFieldValue={(fieldValue)=>{setCharityPayload({...charityPayload, ...{cacRegNumber: fieldValue}})}}
                                        />
                                    </div>
                                    <div className='w-full flex items-center justify-between my-[20px] gap-x-[10px]'>
                                        <div className='w-full'>
                                            <TextField
                                                inputLabel="Registration year"
                                                inputPlaceholder="Year registered with CAC"
                                                inputType="text" 
                                                hasError={validationErrors.registrationYear} 
                                                requiredField={true}
                                                preloadValue={charityPayload.registrationYear || ''}
                                                returnFieldValue={(fieldValue)=>{setCharityPayload({...charityPayload, ...{registrationYear: fieldValue}})}}
                                            />
                                        </div>
                                        <div className='w-full'>
                                            <TextField
                                                inputLabel="Start of operations year"
                                                inputPlaceholder="Year operations started"
                                                inputType="text" 
                                                hasError={validationErrors.startOfOperationsYear} 
                                                requiredField={true}
                                                preloadValue={charityPayload.startOfOperationsYear || ''}
                                                returnFieldValue={(fieldValue)=>{setCharityPayload({...charityPayload, ...{startOfOperationsYear: fieldValue}})}}
                                            />
                                        </div>
                                    </div>
                                    
                                    {!cacSaved && <div className='mt-3'>
                                        <FormButton buttonLabel={<p className='flex items-center gap-x-[8px] font-space-grotesk'>Continue <ChevronIcon className={`w-4 h-4 `} /> </p>} buttonAction={()=>{receiveCACNumber()}} />
                                    </div>}

                                    {cacSaved && <>
                                        <div className='w-full my-[20px]'>
                                            <TextField
                                                inputLabel="Name"
                                                inputPlaceholder="The registered name of your charity"
                                                inputType="text" 
                                                hasError={validationErrors.charityName} 
                                                preloadValue={charityPayload.name || ''}
                                                requiredField={true}
                                                returnFieldValue={(fieldValue)=>{setCharityPayload({...charityPayload, ...{name: fieldValue}})}}
                                            />
                                        </div>

                                        <div className='w-full my-[20px]'>
                                            <TextareaField
                                                inputLabel="About your charity"
                                                inputPlaceholder="Tell us a little bit about your charity"
                                                inputType="text" 
                                                requiredField={true}
                                                hasError={validationErrors.about} 
                                                preloadValue={charityPayload.about || ''}
                                                returnFieldValue={(fieldValue)=>{setCharityPayload({...charityPayload, ...{about: fieldValue}})}}
                                            />
                                        </div>

                                        <div className='w-full my-[20px]'>
                                            <TextField
                                                inputLabel="Website"
                                                inputPlaceholder="What is your website?"
                                                inputType="text" 
                                                requiredField={false}
                                                hasError={false} 
                                                preloadValue={charityPayload.website || ''}
                                                returnFieldValue={(fieldValue)=>{setCharityPayload({...charityPayload, ...{website: fieldValue}})}}
                                            />
                                        </div>

                                        <div className='w-full my-[20px]'>
                                            <SelectField
                                                selectOptions={categories}
                                                inputLabel="Select the category you operate in"
                                                titleField="name"
                                                displayImage={false}
                                                requiredField={true}
                                                imageField=""
                                                preSelectedIndex={categories.findIndex(cat => cat.slug === charityPayload.category)}
                                                fieldId={`charity-category`}
                                                hasError={validationErrors && validationErrors.category}
                                                returnFieldValue={(value) => {selectCategory(value)}}
                                            />
                                        </div>

                                        {subCategoriesActive && <div className='w-full my-[20px]'>
                                            <SelectField
                                                selectOptions={activeSubCategories}
                                                inputLabel="Select sub category"
                                                titleField="name"
                                                displayImage={false}
                                                requiredField={true}
                                                imageField=""
                                                preSelectedIndex={activeSubCategories.findIndex(subCat => subCat.slug === charityPayload.subCategory)}
                                                fieldId={`charity-category`}
                                                hasError={validationErrors && validationErrors.subCategory}
                                                returnFieldValue={(value) => {setCharityPayload({...charityPayload, ...{subCategory: value.slug}})}}
                                            />
                                        </div>}

                                        <div className='w-full my-[20px]'>
                                            <p className='font-[500] text-asparagus text-[16px] '>Funding sources</p>
                                            <p className='mt-[5px] text-[14px]'>Please select all the ways your charity is currently being funded below</p>

                                            <div className='flex items-center gap-x-[10px] flex-wrap mt-[20px]'>
                                                {fundingSources.map((source, sourceIndex)=>(
                                                    <button 
                                                        key={sourceIndex} 
                                                        onClick={()=>{toggleFundingSources(sourceIndex)}} 
                                                        className={`inline mb-[10px] rounded font-space-grotesk text-[13px] hover:bg-lavender-gray p-[10px] font-[500] transition duration-200 ${charityPayload?.fundingSources?.includes(source.slug) ? 'bg-gunmetal-black text-ghost-white' : 'bg-ghost-white text-gunmetal-black'}`}>
                                                            {source.source}
                                                    </button>))}
                                            </div>
                                        </div>

                                        {activeSubStep === 0 && <div className='mt-6 pb-[50px]'>
                                            <FormButton 
                                                buttonLabel={<p className='flex items-center gap-x-[8px] font-space-grotesk'>Continue to contact details <ChevronIcon className={`w-4 h-4 `} /> </p>} 
                                                buttonAction={()=>{saveCharityDetails()}} 
                                            />
                                        </div>}

                                        {activeSubStep > 0 && <>
                                            <div className='border-t mt-[50px] py-[20px]'>
                                                <p className='font-[500] text-asparagus text-[16px]'>Contact details</p>
                                                <p className='mt-[5px] text-[14px]'>Please give us information on how we can contact your charity</p>
                                            </div>
                                            <div className='w-full my-[20px]'>
                                                <TextField
                                                    inputLabel="Email Address"
                                                    inputPlaceholder="Contact email address"
                                                    inputType="text" 
                                                    hasError={validationErrors.contactEmail} 
                                                    preloadValue={charityPayload.contactEmail || ''}
                                                    returnFieldValue={(fieldValue)=>{setCharityPayload({...charityPayload, ...{contactEmail: fieldValue}})}}
                                                    requiredField={true}
                                                />
                                            </div>
                                            <div className='w-full my-[20px]'>
                                                <TextField
                                                    inputLabel="Phone number"
                                                    inputPlaceholder="Contact phone number"
                                                    inputType="text" 
                                                    requiredField={true}
                                                    hasError={validationErrors.contactPhone} 
                                                    preloadValue={charityPayload.contactPhone || ''}
                                                    returnFieldValue={(fieldValue)=>{setCharityPayload({...charityPayload, ...{contactPhone: fieldValue}})}}
                                                />
                                            </div>
                                            <div className='w-full my-[20px]'>
                                                <TextField
                                                    inputLabel="Address"
                                                    inputPlaceholder="Office Physical Address"
                                                    inputType="text" 
                                                    requiredField={true}
                                                    preloadValue={charityPayload.address || ''}
                                                    hasError={validationErrors.address} 
                                                    returnFieldValue={(fieldValue)=>{setCharityPayload({...charityPayload, ...{address: fieldValue}})}}
                                                />
                                            </div>

                                            <div className='w-full mt-4'>
                                                <AutocompleteSelect
                                                    selectOptions = {Nationalities}
                                                    requiredField={true}
                                                    inputLabel = "Country"
                                                    titleField = "name"
                                                    displayImage = {true}
                                                    imageField = "image"
                                                    fieldId = "Country"
                                                    preSelected={charityPayload.countryCode && charityPayload.countryCode !== '' ? charityPayload.countryCode : ''}
                                                    // preSelected={Nationalities[Nationalities.findIndex(nationality => nationality.name === charityPayload.country)]}
                                                    preSelectedLabel='name'
                                                    hasError = {validationErrors && validationErrors.nationality}
                                                    returnFieldValue={(value)=>{setCharityPayload({...charityPayload, ...{
                                                        countryCode: value.code,
                                                        country: value.name
                                                    }})}}
                                                    disabled={false}
                                                />
                                            </div>

                                            <div className='w-full mt-4'>
                                                {charityPayload.countryCode === 'NG' || charityPayload.nationality === 'Nigeria' ?
                                                    <AutocompleteSelect
                                                        selectOptions = {parseNigerianStates()}
                                                        inputLabel = "State"
                                                        requiredField={true}
                                                        titleField = "label"
                                                        displayImage = {false}
                                                        imageField = ""
                                                        fieldId = "State"
                                                        preSelected={charityPayload.state && charityPayload.state !== '' ? charityPayload.state : ''}
                                                        preSelectedLabel='label'
                                                        hasError = {validationErrors && validationErrors.state}
                                                        returnFieldValue={(value)=>{selectState(value)}}
                                                    />
                                                :
                                                    <TextField 
                                                        inputType="text" 
                                                        fieldId="state"
                                                        requiredField={true}
                                                        inputLabel="State" 
                                                        hasError={validationErrors && validationErrors.state === true} 
                                                        preloadValue={charityPayload.state || ''}
                                                        // disabled={true}
                                                        disabled={false}
                                                        returnFieldValue={(value)=>{setCharityPayload({...charityPayload, ...{state: value}})}}
                                                    />

                                                }
                                            </div>

                                            <div className='w-full mt-4'>
                                                {(charityPayload.countryCode === 'NG' || charityPayload.country === 'Nigeria') && lgasEnabled === true ?
                                                    <AutocompleteSelect
                                                        selectOptions = {activeLgas}
                                                        inputLabel = "Lga/City"
                                                        titleField = ""
                                                        displayImage = {false}
                                                        imageField = ""
                                                        disabled={!lgasEnabled}
                                                        fieldId = "userLga"
                                                        preSelected={charityPayload.lga && charityPayload.lga !== '' ? charityPayload.lga : ''}
                                                        requiredField={true}
                                                        preSelectedLabel=''
                                                        hasError = {validationErrors && validationErrors.lga}
                                                        returnFieldValue={(value)=>{
                                                            console.log('selected value:: ', value)
                                                            setCharityPayload({...charityPayload, ...{ lga: value }})}}
                                                    />
                                                :
                                                    <TextField 
                                                        inputType="text" 
                                                        fieldId="city"
                                                        inputLabel="Lga/City" 
                                                        hasError={validationErrors && validationErrors.lga === true} 
                                                        preloadValue={charityPayload.lga || ''}
                                                        // disabled={true}
                                                        requiredField={true}
                                                        disabled={false}
                                                        returnFieldValue={(value)=>{setCharityPayload({...charityPayload, ...{ lga: value }})}}
                                                    />
                                                }
                                            </div>

                                            {activeSubStep === 1 && <div className='mt-6 pb-[50px]'>
                                                <FormButton 
                                                    buttonLabel={<p className='flex items-center gap-x-[8px] font-space-grotesk'>Continue to Key Personnel <ChevronIcon className={`w-4 h-4 `} /> </p>} 
                                                    buttonAction={()=>{saveContactDetails()}} 
                                                />
                                            </div>}
                                        </>}

                                        {activeSubStep > 1 && <>
                                            <div className='border-t mt-[50px] py-[20px]'>
                                                <p className='font-[500] text-asparagus text-[16px] '>Key Personnel</p>
                                                <p className='mt-[5px] text-[14px]'>Please provide at least one of the key personnel of this Charity. You can add more if you need to by clicking "Add key personnel"</p>
                                            </div>

                                            {keyPersonnel?.map((person, personIndex) => (
                                                <div key={personIndex} className='w-full mb-5'>
                                                    <div className='mt-[20px] mb-[10px] flex items-center justify-between'>
                                                        <p>{personIndex + 1}</p>
                                                        {personIndex > 0 && <button onClick={()=>{removePerson(personIndex)}}><TrashIcon className={`w-5 h-5 text-red-500`} /></button>}
                                                    </div>
                                                    <div className='w-full mt-4'>
                                                        <TextField 
                                                            inputType="text" 
                                                            fieldId="person-name"
                                                            inputPlaceholder={`Full name of person`}
                                                            requiredField={true}
                                                            inputLabel="Name" 
                                                            hasError={validationErrors && validationErrors[`person-${personIndex}-name`] === true} 
                                                            preloadValue={person.name || ''}
                                                            // disabled={true}
                                                            disabled={false}
                                                            returnFieldValue={(value)=>{updatePerson(personIndex, 'name', value)}}
                                                        />
                                                    </div>
                                                    <div className='w-full mt-4'>
                                                        <SelectField
                                                            selectOptions={personnelPositions}
                                                            inputLabel="Select position"
                                                            titleField="label"
                                                            displayImage={false}
                                                            requiredField={true}
                                                            imageField=""
                                                            preSelectedIndex={personnelPositions.findIndex(position => position.slug === person.position)}
                                                            fieldId={`person-position`}
                                                            hasError={validationErrors && validationErrors[`person-${personIndex}-position`]}
                                                            returnFieldValue={(value) => {updatePerson(personIndex, 'position', value.value)}}
                                                        />
                                                    </div>
                                                </div>
                                            ))}

                                            <button onClick={()=>{addPerson()}} className='w-max px-3 py-2 border-[2px] border-asparagus hover:border-gunmetal-black bg-white text-asparagus text-[14px] font-space-grotesk hover:text-gunmetal-black text-md font-[550] transition duration-200 flex items-center justify-center rounded'>Add Key Personnel</button>

                                            {activeSubStep === 2 && <div className='mt-6 pb-[50px]'>
                                                <FormButton 
                                                    buttonLabel={<p className='flex items-center gap-x-[8px] font-space-grotesk'>Continue <ChevronIcon className={`w-4 h-4 `} /> </p>} 
                                                    buttonAction={()=>{saveKeyPersonnel()}} 
                                                />
                                            </div>}
                                        </>
                                        }
                                    </>
                                    }



                                </div>}

                                {activeStep === 3 && <div className='w-5/12 mx-auto mt-10 z-20'>

                                    <div className='my-4'>
                                        <Checkbox 
                                            CheckboxLabel="I have read and acknowledge socialseed's Terms and Conditions & Privacy Policy" 
                                            isChecked={termsAccepted} 
                                            checkboxToggleFunction={()=>{setTermsAccepted(!termsAccepted)}}
                                        />
                                    </div>
                                    <div className='my-4'>
                                        <Checkbox 
                                            CheckboxLabel={`I acknowledge that i have the authority to create this account on behalf of ${charityPayload?.name}`} 
                                            isChecked={acknowledged} 
                                            checkboxToggleFunction={()=>{setAcknowledged(!acknowledged)}} 
                                        />
                                    </div>

                                    <div className='mt-6 pb-[50px] flex items-center justify-between'>
                                    <button className='font-space-grotesk text-sm flex items-center gap-x-2' onClick={()=>{setActiveStep(activeStep - 1)}}><ArrowIcon className={`w-4 h-4 -rotate-180 `} /> Back</button>
                                    <div className='w-max'>
                                        <FormButton 
                                            buttonLabel={<p className='flex items-center gap-x-[8px] font-space-grotesk'>Complete signup <ChevronIcon className={`w-4 h-4 `} /> </p>} 
                                            buttonAction={()=>{createCharity()}} 
                                            processing={processing}
                                        />
                                    </div>
                                    </div>

                                </div>}
                            </div>
                        </div>}

                        {signupComplete && <div className='w-full'>
                        <div className='mx-auto w-full px-[40px] lg:w-[60%] xl:w-[40%] text-center'>
                            <div className='w-36 mx-auto mb-[100px]'>
                                <Logo />
                            </div>
                            <span className='text-[60px] mb-[20px]'>🎉</span>
                            <h3 className='font-space-grotesk text-[24px] leading-[28px] text-gunmetal-black font-[500]'>You're all done. Welcome aboard!</h3>
                            <p className='mt-[10px] font-[500]'>Thank you for signing up. We believe that together, we can make a positive impact.</p>
                            <p className='mt-[10px] text-sm font-[500] text-gray-500'>We are currently going through your information and once your account is approved, you will be able to log in to the platform</p>
                            {showConfetti && <Confetti trigger={showConfetti} />}
                        </div>
                        </div>}

                    </div>

                </div>
            }
        </>
    )
}

export default Signup