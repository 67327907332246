import React from 'react'
import { Transition } from '@headlessui/react'

const Notification = ({message, dismissHandler, type, isShowing}) => {

    setTimeout(() => {
        dismissHandler()
    }, 10000);

    return (
        <Transition
            appear={true}
            unmount={true}
            show={isShowing}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full"
        >
            <div className={`${type === 'success' ? 'text-secondary bg-green-400 shadow-green-400/20' : 'text-white bg-red-600 shadow-red-600/20'} min-w-44 font-medium fixed z-50 top-5 left-0 md:left-5 mt-5 block rounded px-6 py-4 capitalize tranisition duration-200 flex justify-between items-center shadow-lg`}>   
                <p>{message}</p>
                <button 
                    onClick={dismissHandler} 
                    className={`${type === 'success' ? 'text-secondary' : 'text-white'} hover:text-opacity-60 p-2 transition duration-200`}>
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                    </svg>
                </button>
            </div>
        </Transition>
    )
}

export default Notification
